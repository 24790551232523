.mod-header {
  @include layout-wrapper();
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;

  .logo {
    @include flex-center();

    .logo-image {
      @include flex-center();
      justify-content: flex-start;
      flex-direction: column;
      margin: 0 20px 0 0;
      z-index: 1;

      &.siemens {
        > a {
          width: 90px;
          height: 15px;
          transform: translate(0, 1px);
          background: url("./../../assets/svgs/logo-siemens.svg") no-repeat;
          background-size: 100%;
        }
      }

      &.healthineers {
        flex-direction: column;
        align-items: flex-start;

        &.show {
          display: flex;
        }

        > a {
          width: 117px;
          height: 28px;
          background: url("./../../assets/svgs/logo-healthineers.svg") no-repeat;
          background-size: 100%;
          //margin: 0 0 4px 0;
        }

        /*
        &:after {
          content: 'Powered by Siemens';
          @include logo-add-txt($grey-light);
          transform: translate(-1px, 0);
          display: block;
        }
        */
      }
    }
  }

  .navigation {
    position: relative;

    &.tabs {
      .mat-tab-link {
        padding-left: 0;
        min-width: 90px;
        font-size: 14px;
        padding: 0 12px;
        height: 65px;
        border-bottom: 2px solid transparent;

        @include mq(desktop-l) {
          min-width: 100px;
          font-size: 16px;
        }

        &:hover,
        &.active {
          color: #2387aa;
          opacity: 1;
          border-bottom: 2px solid #2387aa !important;
        }
      }
    }

    /* ICONS */
    &.icons {
      width: auto;
      height: $header-height;
      right: 0;
      @include flex-center();
      z-index: 1;

      & > div {
        cursor: pointer;

        .icon,
        .icon-label {
          @include copy-txt($font-color-grey);
        }
      }
    }
  }
}
