// button atoms

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button {
        margin-right: 5px;
    }

    &.align-right {
        justify-content: flex-end;
    
        .button {
            margin-right: unset;
            margin-left: 5px;
        }
    }

    &.form-field {
        padding-bottom: 1.25em;
    }
}

.button {
    /* secondary is default */
    @include button(secondary);

    &.ghost {
        @include button(secondary-ghost);
    }

    &.primary {
        @include button(primary);
    }

    &.primary-ghost {
        @include button(primary-ghost);
    }

    &.grey-ghost {
        @include button(grey-ghost);
    }
    
    &.light-ghost {
        @include button(light-ghost);
    }
    

    &.icon {
        flex-direction: row-reverse;
        padding: 9px 10px 9px 15px;
        justify-content: space-between;
    
        &:before {
            margin: 0 0 0 10px;
        }
    }
    &.icon-left {
        flex-direction: row;
        padding: 9px 15px 9px 10px;
        
        &:before {
            margin: 0 10px 0 0;
        }
    }
    &.icon, &.icon-left {
        &:before {
          font-size: 18px;
          line-height: normal !important;
    
          @include mq(desktop-l) {
            font-size: 18px * $desktop-l-factor;
            line-height: normal;
          }
    
          transition: color 0.15s ease-in-out;
          display: inline-block;
          transform: translate(0, -1px);
        }
    }


    &.stretch {
        width: 100%;
    }
    
    &.center {
        justify-content: center !important;
        text-align: center;
        display: block;
        padding: 9px 15px;

        &:before {
            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &.icon {
            padding-right: 30px; 
            &:before {
                right: 10px;
            }
        }
        &.icon-left{
            padding-left: 30px;
            &:before {
                left: 10px;
            }
        }
    }

}
