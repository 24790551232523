
.layout {

  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out;

  > * {
    &:last-child {
      @include mq(print) {
        border-bottom: none !important;
      }
    }
  }


  /**** Layout Wrapper - Main Types ****/

  &.header {
    top: 0;
    height: $header-height;
    background: $white;
    border-bottom: 2px solid $nav-bottom-line;
    position: fixed;
    z-index: 120;

    @include mq(print) {
      display: none;
    }
  }

  &.notification {
    position: fixed;
    top: $header-height;
    z-index: 118;

    @include mq(print) {
      display: none;
    }
  }

  &.main {
    width: 100%;
    padding: $header-height 0 165px 0;
    display: block;
    @include layout-wrapper();

    @include mq(desktop-m) {
      padding-bottom: 135px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .layout {
      padding: 0;
    }
  }

  &.footer {
    left: 0;
    right: 0;
    bottom: 0;
    // padding-top: 60px;
    opacity: 1;
    position: absolute;

    @include mq(print) {
      display: none;
    }

    &.dark {
      background: $bg-grey;
    }

    &.lightfont {

      span,
      a {
        color: $white !important;
      }
    }

    &.show {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }


  /** Layout Wrapper - Background Colors **/
  
  &.light {
    background: $white;
  }
  
  &.grey-light {
    background: $bg-grey-light;
  }
  
  &.grey-dark {
    background: $bg-grey;
  }
  

  /** Layout Wrapper - Distances **/

  &.top-distance {
      margin-top: 130px;

      @include mq(print) {
        margin-top: 0;
      }

      &-small {
        margin-top: $header-height;

          @include mq(print) {
            margin-top: 0;
          }
      }
    }

    &-medium {
      padding-top: 90px;

      @include mq(print) {
        padding-top: 0;
      }
    }



  &.bottom-distance {
    margin-bottom: 100px;

      &-medium {
        margin-bottom: 75px;
      }

      &-small {
          margin-bottom: 50px;
      }
  }

}
