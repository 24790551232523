
// ##############################
// #         COLORS         #
// ##############################

$white: #ffffff;
$black: #000000;
$black-light: #222A2E;
$black-extra-light: #4A4A4A;

$font-color: #2D373C;
$font-color-grey-dark: #555f69;
$font-color-grey: #75899A;
$font-color-grey-extra-light: #becdd7;
$font-color-grey-super-extra-light: #ebf0f5;
$input-border: #cdd9e1;
$input-border-top: #879baa;
$font-color-calc-value: #21292D;
$button-disabled: #cdd9e1;


$sie-dark-blue: rgb(0, 55, 80);
$pwo-button: rgb(35, 135, 170);
$pwo-table-row: rgb(235, 240, 245);

$pwo-button-color: $pwo-button;
$pwo-main-color: $sie-dark-blue;

$pwo-critical-orange: rgb(235, 120, 10);

$nav-light: #B1C2CE;

$bg-survey-1: #E6ECF2;
$bg-survey-2: #E1E7ED;

$quiz-blue: #E6ECF2;
$quiz-blue-dark: #005578;

$tile-msp-border: #E6ECF2;

$info-color: #9EB0BF;

$logo-color: #41aaaa;

$petrol-color: #009999;

$green-chart: #16A605;
$red-chart: #E4630D;
$blue-chart: #43B1CE;
$blue-form: #08426A;

$link-color: #2387aa;
$link-color-hover: #00557D;

$bg-grey: #555f69;
$bg-grey-light: #ebf0f5;
$bg-academy-interactive: #f8f9fb;

$grey: #555f69;
$grey-light: #788791;

$orange: #faa50a;
$orange-dark: #eb780a;

$blue: #2387aa;
$blue-dark: #005578;

$green: #9AA811;
$red: #A82216;
$navigation-active: #1F749A;
$notification-orange: #f58c14;

$input-error: #D70000;
$video-bg: #ECF0F6;

$nav-bottom-line: #DFE6ED;
$tooltip-underline: #95a3ad;
$input-color-disabled: #879baa;
$input-bg-disabled: #DFE6ED;

$document-border: #65747E;
$checkbox-border-active: #094C74;

$color-siemens: #009999;
$color-healthineers: #EB7433;
$color-gamesa: #B0CA02;
$color-alstom: #1A3F94;

$color-siemens-gradient: linear-gradient(to right, #50BEBE 0%, #0099B0 66.66%, #0099CB 100%);
$color-siemens-gradient-inverted: linear-gradient(to right, #0099CB 0%, #0099B0 50%, #009999 83%, #50BEBE 100%);
$color-healthineers-gradient: linear-gradient(to right, #EC7602 0%, #EB5E07 66.66%, #E26A02 100%);
$color-gamesa-gradient: linear-gradient(to right, #D2D741 0%, #9AA812 66.66%, #BEC328 100%);
$color-alstom-gradient: linear-gradient(to right, #3156AD 0%, #1A3F94 66.66%, #1D48A3 100%);


// #############################
// #         DISTANCES         #
// #############################

$v-distance-block: 30px;
$v-distance-block-l: 50px;
$v-distance-headline-xs: 15px;
$v-distance-headline-s: 25px;
$v-distance-headline-sm: 40px;
$v-distance-headline-m: 55px;
$v-distance-p: 20px;


// MAX WIDTH BASED ON MEDIA QUERIES
$max-width-s: 1100px;
$max-width-m: 1300px; // 1200px;
$max-width-l: 1650px; // 1350px;
$max-width-xl: 2000px; // 1800px;


// VERTICAL DISTANCES
$vertical-distance-mobile-s: 10px;
$vertical-distance-mobile-m: 40px;
$vertical-distance-mobile-l: 80px;

$vertical-distance-tablet-s: 15px;
$vertical-distance-tablet-m: 50px;
$vertical-distance-tablet-l: 100px;

$vertical-distance-desktop-s: 20px;
$vertical-distance-desktop-m: 60px;
$vertical-distance-desktop-l: 120px;

$grid-distance: 30px;

// HEADER
$header-height: 65px;


// SCALE FACTOR -> screen size > 1920
// $desktop-l-factor: 1.285; // ugly af
$desktop-l-factor: 1;


// default table padding
$table-padding: 15px;


// MENU WIDTH
$menu-width: 520px;
$menu-width-big: $menu-width * $desktop-l-factor;



// #########################
// #         ICONS         #
// #########################


$icon-presentation: "\e93c";
$icon-slideshow: "\e93b";
$icon-audio: "\e932";
$icon-feature: "\e933";
$icon-footage: "\e934";
$icon-glassdoor-negative: "\e935";
$icon-glassdoor: "\e936";
$icon-picture-set: "\e937";
$icon-picture: "\e938";
$icon-press-release: "\e939";
$icon-videos: "\e93a";
$icon-360-feature: "\e917";
$icon-biography: "\e925";
$icon-embed: "\e926";
$icon-facts-and-forecasts: "\e927";
$icon-infographic: "\e928";
$icon-renren-negative: "\e929";
$icon-renren: "\e92a";
$icon-webcast: "\e92b";
$icon-weixin-negative: "\e92c";
$icon-weixin: "\e92d";
$icon-add-to-download-basket: "\e92e";
$icon-audio-off: "\e92f";
$icon-download-basket: "\e930";
$icon-gif: "\e931";
$icon-career: "\e906";
$icon-clock: "\e907";
$icon-conference: "\e908";
$icon-filter: "\e909";
$icon-investor-relations: "\e90a";
$icon-press: "\e90b";
$icon-question: "\e90c";
$icon-quotation: "\e90d";
$icon-rectification: "\e90e";
$icon-settings: "\e90f";
$icon-tickets: "\e910";
$icon-trade-fair: "\e911";
$icon-webinar: "\e912";
$icon-aparat-negative: "\e913";
$icon-aparat: "\e914";
$icon-tumblr-negative: "\e915";
$icon-tumblr: "\e916";
$icon-fast-backward: "\e918";
$icon-fast-forward: "\e919";
$icon-pause: "\e91a";
$icon-replay: "\e91b";
$icon-skip-backward: "\e91c";
$icon-skip-forward: "\e91d";
$icon-stop: "\e91e";
$icon-audio-down: "\e91f";
$icon-audio-up: "\e920";
$icon-windows-app-store: "\e921";
$icon-apps: "\e600";
$icon-arrow-down-large: "\e605";
$icon-arrow-left-large: "\e606";
$icon-arrow-right-large: "\e607";
$icon-arrow-up-large: "\e608";
$icon-arrow-down-small: "\e609";
$icon-arrow-left-small: "\e60a";
$icon-arrow-right-small: "\e60b";
$icon-arrow-up-small: "\e60c";
$icon-arrow-to-top: "\e60d";
$icon-at: "\e60e";
$icon-bookmark: "\e60f";
$icon-calculate: "\e610";
$icon-calendar: "\e611";
$icon-chat: "\e612";
$icon-check-mark: "\e613";
$icon-circle: "\e614";
$icon-close: "\e615";
$icon-control: "\e616";
$icon-delete: "\e617";
$icon-double-arrow-backward: "\e618";
$icon-double-arrow-down: "\e619";
$icon-double-arrow-forward: "\e61a";
$icon-double-arrow-up: "\e61b";
$icon-download: "\e61c";
$icon-drag: "\e61d";
$icon-edit: "\e61e";
$icon-email: "\e61f";
$icon-ext: "\e620";
$icon-facebook: "\e621";
$icon-facebook-negative: "\e622";
$icon-fax: "\e623";
$icon-file: "\e624";
$icon-avi: "\e625";
$icon-doc: "\e626";
$icon-eps: "\e627";
$icon-jpg: "\e628";
$icon-mov: "\e629";
$icon-mp3: "\e62a";
$icon-mp4: "\e62b";
$icon-pdf: "\e62c";
$icon-png: "\e62d";
$icon-ppt: "\e62e";
$icon-files: "\e62f";
$icon-tif: "\e630";
$icon-vcf: "\e631";
$icon-xls: "\e632";
$icon-zip: "\e633";
$icon-forum: "\e634";
$icon-fullscreen: "\e635";
$icon-globe: "\e636";
$icon-glossary: "\e637";
$icon-google: "\e638";
$icon-google-negative: "\e639";
$icon-home: "\e63a";
$icon-information: "\e63b";
$icon-information-package: "\e63c";
$icon-instagram: "\e63d";
$icon-instagram-negative: "\e63e";
$icon-legal-notice: "\e63f";
$icon-level-up: "\e640";
$icon-linkedin: "\e641";
$icon-linkedin-negative: "\e642";
$icon-list-view: "\e643";
$icon-location-pin: "\e644";
$icon-log-in: "\e645";
$icon-log-out: "\e646";
$icon-menu: "\e647";
$icon-minus: "\e648";
$icon-more: "\e649";
$icon-navigation-gyroscope: "\e64a";
$icon-navigation-mouse: "\e64b";
$icon-newton_product-catalogue: "\e64c";
$icon-outline: "\e64d";
$icon-phone: "\e64e";
$icon-play: "\e64f";
$icon-plus: "\e650";
$icon-print: "\e651";
$icon-rating: "\e652";
$icon-region: "\e653";
$icon-rss: "\e655";
$icon-scaling: "\e656";
$icon-search: "\e657";
$icon-share-ios: "\e658";
$icon-share-newton-android: "\e659";
$icon-shopping-cart: "\e65a";
$icon-show-location: "\e65b";
$icon-single-arrow-backward: "\e65c";
$icon-single-arrow-down: "\e65d";
$icon-single-arrow-forward: "\e65e";
$icon-single-arrow-up: "\e65f";
$icon-slider: "\e660";
$icon-social-feed: "\e661";
$icon-audio-on: "\e663";
$icon-subscribe: "\e664";
$icon-theenergyblog: "\e665";
$icon-theenergyblog-negative: "\e666";
$icon-apple-mac-app-store: "\e922";
$icon-android-app-store: "\e923";
$icon-shuffle: "\e924";
$icon-tile-view: "\e667";
$icon-twitter: "\e668";
$icon-twitter-negative: "\e669";
$icon-user-account: "\e66a";
$icon-video-chat: "\e66b";
$icon-warning: "\e66c";
$icon-youtube: "\e66d";
$icon-youtube-negative: "\e66e";
$icon-zoom: "\e66f";
$icon-weibo: "\e900";
$icon-weibo-negative: "\e901";
$icon-youku: "\e902";
$icon-youku-negative: "\e903";
$icon-support: "\e904";
$icon-service: "\e905";




// ##############################
// #         DEPRECTAED         #
// ##############################


// LIGHTBOX SIZES
$lightbox-small: 560px;
$lightbox-medium: 768px;
$lightbox-large: 1000px;

$close-icon-default-distance: 50px;
$lightbox-x-large: 1440px;
