// @import "@angular/material/prebuilt-themes/indigo-pink.css";

@import '../styles/_global/queries';
@import '../styles/_global/variables';
@import "./_global/material";
@import '../styles/_global/normalize';
@import '../styles/_global/queries';
@import '../styles/_global/mixins';
@import '../styles/_global/grid';
@import '../styles/_global/typography';
@import '../styles/_global/buttons';
@import '../styles/_global/icons';
// @import '../styles/_global/form';
@import '../styles/_global/base';
// @import '../styles/_global/pwo';
@import '../styles/_global/print';
@import '../styles/_global/layout';


@import '../styles/atoms/a-text';
@import '../styles/atoms/a-button';

/* Modules */
@import '../styles/modules/mod-footer';
@import '../styles/modules/mod-header';
@import '../styles/modules/mod-menu';
@import '../styles/modules/mod-scrollbar';
@import '../styles/modules/mod-scroll-to-top';
@import '../styles/modules/mod-select';
@import '../styles/modules/mod-tooltip';

@import '../styles/modules/mod-table';
@import '../styles/modules/mod-form';

@import 'pwo-forms/_theme.scss';


@include mq(mobile) {
    .hide-mobile {
        display: none;
    }
}

@include mq(mobile-s) {
    .hide-mobile-s {
        display: none;
    }
}

@include mq(tablet) {
    .hide-desktop {
        display: none;
    }
}

[hidden] {
    display: none!important;
}

// .mat-step-icon-content span {
//     font-family: "Siemens Sans"
// }

// .mat-select-value-text,
// .mat-option-text {
//     font-family: "Siemens Sans"
// }

// .mat-form-field-label {
//     font-family: "Siemens Sans Bold" !important;
//     color: $blue !important;
// }

// .mat-step-header .mat-step-icon-selected {
//     background-color: $blue
// }

// .mat-form-field-ripple {
//     background-color: $blue !important;
// }

// .mat-checkbox-checked.mat-accent .mat-checkbox-background {
//     background-color: $blue !important;
// }

// input[type=text]:disabled {
//     font-family: 'Siemens Sans' !important;
// }

// html {
//     overflow: -moz-scrollbars-vertical;
//     overflow-y: scroll;
// }

// .select-dropdown {
//     border: 1px solid #ebf0f5 !important;
//     padding: 5px !important;
// }


// .incomplete {
//     background: url('assets/svgs/incomplete.svg') no-repeat;
// }

// #checksign {
//     background: url('assets/svgs/check-sign.svg') no-repeat;
// }

// .user_icon {
//     width: 25px;
//     height: 25px;
//     background: url('assets/images/icons8-user-32.png') no-repeat;
//     background-size: contain;
// }

// .logout_icon {
//     width: 25px;
//     height: 25px;
//     background: url('assets/images/logout.png') no-repeat;
//     background-size: contain;
// }

// .mat-header-row {
//     position: sticky;
//     top: 0;
//     background: white;
//     z-index: 100;
// }

// .mat-column-ProjectId {
//     flex-basis: 2.5rem;
// }

// mat-header-cell {
//     background-color: #2387aa !important;

//     .mat-sort-header-container {
//         display: inline-flex,
//     }

//     &.filter-active {
//         //color: #099;
//         color: $pwo-button-color;
//     }
// }

// mat-cell {
//     .mat-form-field-wrapper {
//         width: 95%;
//     }

//     .mat-form-field-infix {
//         width: auto;
//     }

//     .mat-checkbox-layout {
//         display: table-row;
//     }

//     .pull-right {
//         float: right;
//         padding-right: 1.2rem;
//     }

//     .center-checkbox {
//         display: block;
//         text-align: center;
//     }
// }

// .mat-table {
//     border-bottom: 1.5px solid #dfe6ed;

//     .mat-header-row {
//         background-color: #2387aa;

//         .mat-header-cell {
//             color: white;

//             & > span, .pwo-filter.header-filter-icon {
//                 color: white;
//             }

//         }

//         border: solid 1px #2387aa;
//         border-bottom: 0px;

//         .mat-sort-header-arrow {
//           color: white;
//       }
//     }

//     .mat-row {
//         border-bottom-width: 0;
//         border-left: 1.5px solid #dfe6ed;
//         border-right: 1.5px solid #dfe6ed;
//     }

//     .mat-sort-header-stem,
//     .mat-sort-header-indicator {
//         color: white;
//     }

// }