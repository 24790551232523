
.tooltip {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  color: $font-color;

  &--light {
    color: $white;
  }
}

.ng-tooltip {
  @include copy-txt-small($white);
  max-width: 200px;
  color: $white;
  text-align: center;
  background: $font-color !important;
  border-radius: 0 !important;
  padding: 9px 12px !important;
  position: absolute;
  z-index: 1000;
  display: block;
  opacity: 0;
  transition: opacity 300ms;

  &-show {
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  &-top {
    &::after {
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: $font-color transparent transparent transparent;
    }
  }

  &-bottom {
    &::after {
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-color: transparent transparent $font-color transparent;
    }
  }

  &-left {
    &::after {
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent transparent transparent $font-color;
    }
  }

  &-right {
    &::after {
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-color: transparent $font-color transparent transparent;
    }
  }
}
