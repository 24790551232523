@mixin button($type) {
  @include fontstack(siemens-sans-bold, 14px, 0, 0, null, 400);
  outline: none;
  transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border-color 0.15s ease-in-out;
  @include flex-center();
  text-align: left;
  cursor: pointer;
  justify-content: flex-start;
  padding: 9px 15px 9px 10px;
  min-height: 40px;
  border-radius: 0;
  line-height: normal;
  position: relative;

  @include mq(desktop-l) {
    font-size: 14px * $desktop-l-factor;
    line-height: normal;
  }

  

  @if $type == ie-left {
    padding: 8px 15px 10px 10px;

    &.icon, &.icon-right {
      &:before {
        transform: translate(0, 0);
      }
    }
  }

  @if $type == ie-right {
    padding: 8px 10px 10px 15px;

    &.icon, &.icon-right {
      &:before {
        margin: 0 0 0 10px;
        transform: translate(0, 0);
      }
    }
  }

  @if $type == primary {
    color: $white;
    background: $orange;
    border: 2px solid $orange;
    margin-bottom: 5px;

    &.icon, &.icon-right {
      &:before {
        color: $white;
      }
    }

    &:hover {
      background: $orange-dark;
      border-color: $orange-dark;
    }
  }

  @if $type == secondary {
    color: $white;
    background: $blue;
    border: 2px solid $blue;

    &.icon, &.icon-right {
      &:before {
        color: $white;
      }
    }

    &:hover {
      background: $blue-dark;
      border-color: $blue-dark;
    }
  }

  @if $type == primary-ghost {
    color: $orange;
    background: transparent;
    border: 2px solid $orange;

    &.icon, &.icon-right {
      &:before {
        color: $orange;
      }
    }

    &:hover {
      color: $white;
      background: $orange-dark;
      border-color: $orange-dark;

      &.icon, &.icon-right {
        &:before {
          color: $white;
        }
      }
    }
  }

  @if $type == secondary-ghost {
    color: $blue;
    background: transparent;
    border: 2px solid $blue;

    &.icon, &.icon-right {
      &:before {
        color: $blue;
      }
    }

    &:hover {
      color: $white;
      background: $blue-dark;
      border-color: $blue-dark;

      &.icon, &.icon-right {
        &:before {
          color: $white;
        }
      }
    }
  }

  @if $type == light-ghost {
    color: $white;
    background: transparent;
    border: 2px solid $white;

    &.icon, &.icon-right {
      &:before {
        color: $white;
      }
    }

    &:hover {
      color: $font-color;
      background: $white;
      border-color: $white;

      &.icon, &.icon-right {
        &:before {
          color: $font-color;
        }
      }
    }
  }

  @if $type == grey-ghost {
    color: $font-color-grey;
    background: transparent;
    border: 2px solid $font-color-grey;

    &.icon, &.icon-right {
      &:before {
        color: $font-color-grey;
      }
    }

    &:hover {
      color: $white;
      background: $font-color-grey;
      border-color: $font-color-grey;

      &.icon, &.icon-right {
        &:before {
          color: $white;
        }
      }
    }
  }

  &:disabled {
    background: $button-disabled;
    border-color: $button-disabled;
    cursor: default;
  }

  @if (str-index($type, "ghost")) {
    &:disabled {
      background: transparent !important;
      color: $button-disabled;

      &.icon, &.icon-right {
        &:before {
          color: $button-disabled;
        }
      }
    }
  }
}
