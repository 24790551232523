// Grid

.grid {
    display: grid;
    grid-gap: $grid-distance;
    margin: $grid-distance 0;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));

    &.small-columns {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    &.big-columns {
        grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    }

    &.two-columns {
        grid-template-columns: repeat(2, 1fr);
    
    }
    &.three-columns {
        grid-template-columns: repeat(3, 1fr);
    }
    
    &.four-columns {
        grid-template-columns: repeat(4, 1fr);
    }

    .item {

        &.tall {
            grid-row: span 2;
        }
        &.tall-3 {
            grid-row: span 3;
        }
        &.tall-4 {
            grid-row: span 4;
        }

        &.wide {
            grid-column: span 2;
        }
        &.wide-3 {
            grid-column: span 3;
        }
        &.wide-4 {
            grid-column: span 4;
        }
    }

}
