// mixin clearfix
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// mixin media query
@mixin mq($type) {
  @if map-has-key($breakpoints, $type) {
    @media #{map-get($breakpoints, $type)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$type}!";
  }
}


// mixin flex center
@mixin flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

// mixin aspect ratio
@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
      content: '';
      width: 100%;
      padding-top: ($height / $width) * 100%;
      display: block;
  }
}


// hide text
@mixin hide-text() {
    position: absolute;
    left: -9999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

@mixin headline-margins() {
  margin-bottom: 5px;
  margin-top: 8px;
}

// mixin aspect ratio
@mixin aspect-ratio-extra($width, $height, $additional-padding) {
    position: relative;

    &:before {
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
        display: block;
    }

    @include mq(desktop-s) {
        &:before {
            padding-bottom: $additional-padding;
        }
    }
}

@mixin layout-wrapper() {

  width: 92.5%;
  margin: 0 auto;
  max-width: $max-width-s;
  transition: max-width 0.25s ease-in-out;
  position: relative;

  @include mq(desktop-m) {
    max-width: $max-width-m;
  }

  @include mq(desktop-l) {
    max-width: $max-width-l;
  }

  @include mq(desktop-xl) {
    max-width: $max-width-xl;
  }
}

@mixin vertical-margin-top($type) {

  @if $type == s {
    margin-top: $vertical-distance-mobile-s;

    @include mq(desktop-s) {
      margin-top: $vertical-distance-tablet-s;
    }

    @include mq(desktop-l){
      margin-top: $vertical-distance-desktop-s;
    }
  }
  @else if $type == m  {
    margin-top: $vertical-distance-mobile-m;

    @include mq(desktop-s) {
      margin-top: $vertical-distance-tablet-m;
    }

    @include mq(desktop-l){
      margin-top: $vertical-distance-desktop-m;
    }
  }
  @else if $type == l {
    margin-top: $vertical-distance-mobile-l;

    @include mq(desktop-s) {
      margin-top: $vertical-distance-tablet-l;
    }

    @include mq(desktop-l){
      margin-top: $vertical-distance-desktop-l;
    }
  }
}

@mixin vertical-margin-bottom($type) {

  @if $type == s {
    margin-bottom: $vertical-distance-mobile-s;

    @include mq(desktop-s) {
      margin-bottom: $vertical-distance-tablet-s;
    }

    @include mq(desktop-l){
      margin-bottom: $vertical-distance-desktop-s;
    }
  }
  @else if $type == m  {
    margin-bottom: $vertical-distance-mobile-m;

    @include mq(desktop-s) {
      margin-bottom: $vertical-distance-tablet-m;
    }

    @include mq(desktop-l){
      margin-bottom: $vertical-distance-desktop-m;
    }
  }
  @else if $type == l {
    margin-bottom: $vertical-distance-mobile-l;

    @include mq(desktop-s) {
      margin-bottom: $vertical-distance-tablet-l;
    }

    @include mq(desktop-l){
      margin-bottom: $vertical-distance-desktop-l;
    }
  }
}

@mixin vertical-padding-top($type) {

  @if $type == s {
    padding-top: $vertical-distance-mobile-s;

    @include mq(desktop-s) {
      padding-top: $vertical-distance-tablet-s;
    }

    @include mq(desktop-l){
      padding-top: $vertical-distance-desktop-s;
    }
  }
  @else if $type == m  {
    padding-top: $vertical-distance-mobile-m;

    @include mq(desktop-s) {
      padding-top: $vertical-distance-tablet-m;
    }

    @include mq(desktop-l){
      padding-top: $vertical-distance-desktop-m;
    }
  }
  @else if $type == l {
    padding-top: $vertical-distance-mobile-l;

    @include mq(desktop-s) {
      padding-top: $vertical-distance-tablet-l;
    }

    @include mq(desktop-l){
      padding-top: $vertical-distance-desktop-l;
    }
  }
}

@mixin vertical-padding-bottom($type) {

  @if $type == s {
    padding-bottom: $vertical-distance-mobile-s;

    @include mq(desktop-s) {
      padding-bottom: $vertical-distance-tablet-s;
    }

    @include mq(desktop-l){
      padding-bottom: $vertical-distance-desktop-s;
    }
  }
  @else if $type == m  {
    padding-bottom: $vertical-distance-mobile-m;

    @include mq(desktop-s) {
      padding-bottom: $vertical-distance-tablet-m;
    }

    @include mq(desktop-l){
      padding-bottom: $vertical-distance-desktop-m;
    }
  }
  @else if $type == l {
    padding-bottom: $vertical-distance-mobile-l;

    @include mq(desktop-s) {
      padding-bottom: $vertical-distance-tablet-l;
    }

    @include mq(desktop-l){
      padding-bottom: $vertical-distance-desktop-l;
    }
  }
}

@mixin vertical-padding($size) {

  @if $size == s {
    padding-top: 50px;
    padding-bottom: 50px;
  } @else if $size == m {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}


@mixin input-border() {
  border: 1px solid $input-border;
}

@mixin input-border-focus() {
  border: 1px solid $blue-dark;
}

@mixin input-border-error() {
  border: 1px solid $input-error;
}


@mixin transition($type, $time) {

  @if $time == s {
    transition: $type 0.15s ease-in-out;
  }

  @if $time == m {
    transition: $type 0.5s ease-in-out;
  }

  @if $time == l {
    transition: $type 1s ease-in-out;
  }

  @if $time == xl {
    transition: $type 2s ease-in-out;
  }
}

@mixin transparency-bg($color,$alpha) {
  background: rgba($color,$alpha);
}


@mixin list-item($type) {
  width: 100%;
  @include link-nav-primary;
  @include flex-center();
  @include transition(background, s);
  cursor: pointer;
  position: relative;
  padding: 0 20px;

  @if $type == new {
      background: $black;
  }

  @if $type == single {
      justify-content: flex-start;
  }

  a,
  label
  {
      @if $type == reg {
          @include copy-txt-chapter-title($font-color-grey);
      }

      @if $type == new {
          @include copy-txt-chapter-title($white);
      }

      @if $type == single {
          @include copy-txt-chapter-title($font-color-grey);
      }
  }

  a {
      width: 100%;
      display: block;

      @if $type == reg or $type == new {
          padding: 20px 50px 17px 0;
      }

      @if $type == single {
          padding: 20px 10px 17px 0;
      }

      @include transition(all, s);
  }

  input[type="checkbox"] + label {

      &:before {
          @if $type == reg {
              border: 1px solid $font-color-grey !important;
          }
          @if $type == new {
              border: 1px solid $white !important;
          }
      }

      &:after {
          width: 15px;
          height: 12px;
          @if $type == reg {
              // @include icon($icon-checkbox-check); FIXME
          }
          @if $type == new {
              // @include icon($icon-checkbox-check-white); FIXME
          }
      }
  }

  &:before {
      content: '';
      left: 20px;
      bottom: -1px;
      width: calc(100% - 40px);
      height: 1px;
      background: $font-color-grey-super-extra-light;
      position: absolute;
      pointer-events: none;
  }

  @if $type == reg or $type == new {
      &:after {
          content: '';
          top: 50%;
          right: 19px;
          width: 9px;
          height: 15px;

          @if $type == reg {
              // @include icon($icon-tile-arrow-dark); FIXME
              opacity: 0.2;
          }
          @if $type == new {
              // @include icon($icon-tile-arrow-light); FIXME
              opacity: 1;
          }

          background-size: 9px 15px;
          transform: translate(0, -8px);
          position: absolute;
          @include transition(opacity, s);
          pointer-events: none;
      }
  }

  &:hover {

      background: $link-color-hover;

      &:before {
          content: none;
      }

      &:after {
          opacity: 1;
          // // @include icon($icon-tile-arrow-light); FIXME FIXME
          background-size: 9px 15px;
      }

      a,
      label {
          @include copy-txt-chapter-title($white);
      }

      input[type="checkbox"] + label {

          &:before {
              border: 1px solid $white !important;
          }

          &:after {
              width: 15px;
              height: 12px;
              // @include icon($icon-checkbox-check-white); FIXME
          }
      }
  }
}


@mixin input-inactive() {
  background: #dfe6ed;
  border: 1px solid #cdd9e1;
  color: #879baa;

  ::-webkit-input-placeholder {
      color: #879baa;
  }
  ::-moz-placeholder {
      color: #879baa;
  }
  :-ms-input-placeholder {
      color: #879baa;
  }
  :-moz-placeholder {
      color: #879baa;
  }
}

@mixin print-clean() {
    color: $font-color !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    min-height: 0 !important;
    max-height: 100% !important;
}

@mixin print-section() {
    border-bottom: 2px solid $tile-msp-border !important;
    margin-bottom: 50px !important;
    padding-bottom: 50px !important;
}

@mixin print-bottom-distance() {
    margin-bottom: 50px !important;
}


@mixin reset-values($type) {

  @include flex-center();
  justify-content: flex-end;
  text-align: right;
  cursor: pointer;

  > div,
  > span {
    @include copy-txt($font-color);
  }

  &:after {
    content: '';
    margin-left: 6px;
    transform: translate(0,-2px);
    // @include icon($icon-reload); FIXME
    display: inline-block;
    transition: transform 0.2s ease-in-out;
  }

  @if $type == relative {
    position: relative;

  } @else {
    position: absolute;
    right: 20px;
  }
}

