.pwo {
  // height: 30px;
  // max-height: 30px;
  // display: flex;
  // align-items: center;
  // border: 1.5px solid $nav-bottom-line;
  // background-color: white;
  // margin-bottom: 7px;
  // margin-top: 7px;
  // &.mat-focused {
  //     border-color: #009688;
  // }
  input,
  textarea {
    position: absolute;
    top: 2px;
    font: 400 13.3333px Arial !important;
    height: 27px !important;
    padding: 10px !important;
  }
  .mat-form-field-subscript-wrapper {
    display: none;
  }
  .mat-form-field-underline {
    height: 0 !important;
    display: none;
  }
  .mat-form-field-wrapper {
    padding: 0px !important;
    width: 100%;
  }
  .mat-form-field-flex {
    align-items: center;
  }
  .mat-form-field-wrapper,
  .mat-form-field-flex,
  .mat-form-field-label-wrapper {
    height: 30px !important;
  }
  .mat-form-field-label-wrapper {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    padding: 6px;
  }
  .mat-form-field-infix {
    font: 400 13.3333px Arial !important;
    padding: 0 !important;
    height: 30px;
    border-top: 0 !important;
    label {
      margin-left: 3px;
      position: initial;
    }
  }
  &.mat-form-field-should-float {
    label {
      margin-left: -4px;
      margin-bottom: 3px;
    }
  }
}

.newton-form {

  .mat-form-field {
    width: 100%;

    .mat-form-field-flex {
      @include input-border();

      .mat-form-field-infix {
        padding: 6px 15px 8px;
        border-top: 12px solid transparent;

        input, textarea {
          font-size: 14px;
        }

        // @include mq(desktop-l) {
        //   border-top: 16px solid transparent;
          
        //   input, textarea {
        //     font-size: 14px * $desktop-l-factor;
        //     margin-top: 2px;
        //   }
        // }
      }

      .mat-form-field-label-wrapper {
        top: -18px;
        padding-top: 15px;

        // @include mq(desktop-l) {
        //   top: -26px;
        // }

        .mat-form-field-label {
          margin-left: 15px;
          color: $font-color;
        }
      }
    }
    .mat-form-field-underline {
      height: 0 !important;
      display: none;
    }

    &.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-8px) !important;
      font-size: 10px;

      // @include mq(desktop-l) {
      //   transform: translateY(-6px) !important;
      //   font-size: 10px * $desktop-l-factor;        
      // }
    }

    &.mat-focused {
      .mat-form-field-flex {
        @include input-border-focus();
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-flex {
        background-color: $input-bg-disabled;
        color: $input-color-disabled;
      }
    }

    .mat-form-field-prefix {
      .mat-icon {
        transform: translate(7px, 2px);
      }
    }
    .mat-form-field-suffix {
      .mat-icon {
        transform: translate(-7px, 2px);
      }
      .a-icon {
        display: inline-block;
        padding-right: 8px;
        transform: translate(0,-3px);
        font-size: 16px;
      }
    }

    .mat-form-field-subscript-wrapper {
      margin-top: 0px;

      & > div > .mat-error {
        padding: 8px 15px;
      }
      .mat-error {
        background-color: $input-error;
        color: white;
        width: fit-content;  
      }

      .mat-hint {
        padding: 2px;
      }
    }

    &.stretch {
      height: 100%;

      * {
        height: 100%;
      }
    }

    .mat-select-arrow-wrapper {
      .mat-select-arrow {
        @include icon($icon-arrow-down-large);
        @include fontstack();
        border: none;
        width: 14px;
        height: 16px;
        margin-top: -8px;
      }
    }
    
    .mat-datepicker-toggle {
      .mat-icon-button {
        @include icon($icon-calendar);
        @include fontstack();
        margin-right: 12px;
        transform: translateY(-3px);

        // @include mq(desktop-l) {
        //   transform: translateY(-6px);
        // }

        .mat-button-wrapper, .mat-button-ripple {
          display: none;
        }
      }
    }

    &.mat-form-field-type-mat-select {
      .mat-form-field-infix {
        padding-bottom: 6px;
      }
    }

    .mat-chip-input {
      margin-bottom: 7px;
    }
  }

  .ng-dirty.ng-invalid,
  .ng-touched.ng-invalid {
    .mat-form-field-flex {
      @include input-border-error();
    }
    .mat-form-field-label {
      color: $input-error !important;
    }
  }
  
}
