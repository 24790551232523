@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$pwo-palette: (
  A100: $quiz-blue,
  A200: $blue,
  A400: $blue-dark,
  contrast: (
    A100: $font-color,
    A200: $font-color-grey-super-extra-light,
    A400: $font-color-grey-super-extra-light,
  )
);

$pwo-palette-accent: (
  A100: $white,
  A200: $black-light,
  A400: $black-extra-light,
  contrast: (
    A100: $font-color,
    A200: $font-color-grey,
    A400: $font-color-grey-super-extra-light,
  )
);

$pwo-app-primary: mat.define-palette($pwo-palette, A200, A400, A100);
$pwo-app-accent:  mat.define-palette($pwo-palette-accent, A200, A400, A100);

// Create the theme object (a Sass map containing all of the palettes).
$pwo-app-theme: mat.define-light-theme($pwo-app-primary, $pwo-app-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pwo-app-theme);

.mat-checkbox-semichecked {
  .mat-checkbox-background {
    background-color: mat.get-color-from-palette($pwo-app-accent, default);
    margin: 4px;
  }
}

.mat-header-cell {
  .mat-sort-header-button {
    text-align: left;
  }
}
