.mod-menu {

  // Backdrop
  &:before {
    content: "";
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    transition: background 0.5s ease-in-out, right 0s ease-in-out;
    transition-delay: 0s, 0.5s;
  }

  > .m-content {
    top: 0;
    right: -$menu-width;
    width: $menu-width;
    height: 100%;
    background: $white;
    position: fixed;
    overflow: hidden;
    z-index: 9999;
    @include transition(right, m);

    @include mq(mobile) {
      right: -100%;
      width: 100%;
    }

    @include mq(desktop-l) {
      right: -$menu-width-big;
      width: $menu-width-big;
    }

    > .m-navigation {
      padding: 22px;
      width: $menu-width;
      height: 64px;
      right: 0;
      top: 0;
      background: $white;
      border-bottom: 1px solid $font-color-grey-extra-light;
      position: relative;
      // @include transition(right, m);

      @include mq(mobile) {
        width: 100%;
      }

      @include mq(desktop-l) {
        width: $menu-width-big;
        padding-top: 16px;
      }

      > .close-menu {
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        cursor: pointer;
        z-index: 2;
      }

      > .m-headline {
        padding: 0 30px 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include headline-txt($font-color);

        &.center {
          text-align: center;
          padding-left: 30px;
        }
      }
    }

    > .m-content {
      right: 0;
      height: 100%;
      overflow-y: auto;
      position: relative;
      @include transition(all, m);

      > .m-layer {
        @include headline-txt($font-color);
        overflow-y: auto;
        width: $menu-width;
        height: 100%;
        right: 0;
        top: 60px;
        background: $white;
        position: absolute;
        @include transition(right, m);
        overflow-x: hidden;

        @include mq(mobile) {
          width: 100%;
        }
        @include mq(desktop-l) {
          width: $menu-width-big;
        }
      }
    }
  }

  &.show {
    &:before {
      background: rgba(0, 0, 0, 0.6);
      right: 0;
      transition: background 0.5s ease-in-out, right 0s ease-in-out;
      transition-delay: 0s, 0s;
    }

    > .m-content {
      right: 0;

      > .m-bottom-wrapper {
        right: 0;
      }
    }
  }
}

.noscroll {
  overflow: hidden;
}
