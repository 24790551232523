.mod-footer {

    @include layout-wrapper;
    margin: 0 auto 20px auto;
    @include flex-center();
    flex-direction: column;

    @include mq(desktop-m) {
        flex-direction: row;
    }

    .m-info {
        margin: 0 0 10px 0;

        @include mq(desktop-m) {
            margin: 0;
        }

        span {
            @include footer-txt($info-color);
            margin: 0 9px;
            cursor: default;
        }
    }

    .m-footer-nav {

        ul {
            li {
                margin: 0 9px;
                float: left;

                a {
                    @include footer-txt($info-color);
                }
            }
        }
    }
}
