.ng-select {

  .ng-select-container {
    padding: 10px;
    background-color: $white;
    border: 1px solid $input-border;
    height: 44px;
    border-radius: 0;
    align-items: center;
    cursor: pointer !important;

    .ng-value-container {
      .ng-placeholder {

        @include copy-txt($font-color);
      }

      .ng-value {
        transform: translate(0, -1px);

        .ng-value-label {
          @include copy-txt($font-color);
        }
      }
    }

    .ng-arrow-wrapper {

      // @include icon($icon-tile-arrow-dark); FIXME
      width: 9px;
      height: 15px;
      transform: rotate(90deg) translate(0, 0);
      right: 4px;
      opacity: 0.2;

      &:hover {
        opacity: 1;
      }
    }

    &.ng-has-value  {

      .ng-placeholder {
        display: none
      }
    }
  }

  .ng-dropdown-panel {
    border: 1px solid $input-border;
    background: $white;

    .ng-dropdown-panel-items {

      .ng-option {

        list-style: none;
        padding: 7px 10px 7px 10px;
        background: $white;
        cursor: pointer;
        position: relative;
        @include copy-txt($font-color);
        transition: padding 0.2s;

        &:before {
          content: '';
          top: 50%;
          left: 12px;
          // @include icon($icon-tile-arrow-light); FIXME
          width: 8px;
          height: 13px;
          background-size: 100%;
          transform: rotate(0deg) translate(-20px, -7px);
          position: absolute;
          opacity: 0;
          z-index: 1;
          @include transition(all, s);
        }

        &:hover {
          background: $link-color-hover;
          color: $white;
          padding-left: 30px;

          &:before {
            opacity: 1;
            transform: rotate(0deg) translate(0, -7px);
          }
        }

        &-selected,
        &-marked {
          background: $link-color-hover;
          color: $white
        }

        &-disabled {
          color: $font-color-grey-extra-light;
        }
      }
    }
  }

  &-disabled {
    .ng-select-container {
      background: $input-bg-disabled;
      cursor: default !important;

      .ng-value-container {
        .ng-value {
          .ng-value-label {
            color: $input-color-disabled;
          }
        }
      }
    }
  }
}
