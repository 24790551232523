
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

html {
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;

  &.ie {
    .a-button {
      @include button(ie-left);

      &--right-icon {
        @include button(ie-right);
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
ul,
li {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

ul {
  list-style: none;

  li {
    @include copy-txt($font-color);
  }
}

ol {
  margin: 0;
  padding: 0;

  li {
    @include copy-txt($font-color);
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}

menu {
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

figure {

  img {
    width: 100%;
    height: auto;
  }

  figcaption {
    @include copy-txt-small($font-color-grey);
  }
}
