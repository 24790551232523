body, .scrollbar {

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px; // for vertical scrollbars
        background-color: #f6f6f6;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: lightgray;
    }
}
