
@media print {

  * {
      line-height: 1.4 !important;
  }

  *,
  *:before,
  *:after {
      background: transparent !important;
      box-shadow: none !important;
      text-shadow: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
      widows:5;
      orphans: 3;
      page-break-after: avoid;
  }

  app-main a,
  .m-link,
  .m-button-wrapper,
  .a-button {
      display: none !important;
  }

  .mod-awards {
      display:none !important;
  }
  .mod-disclaimer {
      display:none !important;
  }

  .lay-wrapper--vmb-l {
    margin: 0 !important;
    padding: 0 !important;
  }
  .lay-wrapper--footer {
    display: none;
  }

  .sip-no-show {
    display: none;
  }
  .sip-show {
    display: block !important;
    margin-bottom: 50px;
  }
}

@page {
  margin: 2.9cm 2.9cm;
}
