mat-header-cell,
mat-footer-cell,
mat-cell {
    font-size: 14px !important;
    span {
        font-size: 14px !important;
        max-width: 100%;
    }
}

.number-cell {
    display: flex;
    justify-content: flex-end;
}

.mat-row:nth-child(2n-1) {
    background-color: white; // background: rgb(235, 240, 245);
}

mat-header-row {
    position: sticky;
    top: 0;
}