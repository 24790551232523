@import "variables";

@mixin icon($type) {

    &:before {
        font-family: 'siemens-webicons-webfont' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: $type;
    }
}

@font-face {
    font-family: 'siemens-webicons-webfont';
    src: url('./../../assets/fonts/siemens-webicons-webfont.eot?sdkjlnf');
    src: url('./../../assets/fonts/siemens-webicons-webfont.eot?sdkjlnf#iefix') format('embedded-opentype'),
    url('./../../assets/fonts/siemens-webicons-webfont.ttf?sdkjlnf') format('truetype'),
    url('./../../assets/fonts/siemens-webicons-webfont.woff?sdkjlnf') format('woff'),
    url('./../../assets/fonts/siemens-webicons-webfont.svg?sdkjlnf#siemens-webicons-webfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon--"], [class*=" icon--"] {
  &:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'siemens-webicons-webfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon--presentation {
  &:before {
    content: $icon-presentation;
  }
}
.icon--slideshow {
  &:before {
    content: $icon-slideshow;
  }
}
.icon--audio {
  &:before {
    content: $icon-audio;
  }
}
.icon--feature {
  &:before {
    content: $icon-feature;
  }
}
.icon--footage {
  &:before {
    content: $icon-footage;
  }
}
.icon--glassdoor-negative {
  &:before {
    content: $icon-glassdoor-negative;
  }
}
.icon--glassdoor {
  &:before {
    content: $icon-glassdoor;
  }
}
.icon--picture-set {
  &:before {
    content: $icon-picture-set;
  }
}
.icon--picture {
  &:before {
    content: $icon-picture;
  }
}
.icon--press-release {
  &:before {
    content: $icon-press-release;
  }
}
.icon--videos {
  &:before {
    content: $icon-videos;
  }
}
.icon--360-feature {
  &:before {
    content: $icon-360-feature;
  }
}
.icon--biography {
  &:before {
    content: $icon-biography;
  }
}
.icon--embed {
  &:before {
    content: $icon-embed;
  }
}
.icon--facts-and-forecasts {
  &:before {
    content: $icon-facts-and-forecasts;
  }
}
.icon--infographic {
  &:before {
    content: $icon-infographic;
  }
}
.icon--renren-negative {
  &:before {
    content: $icon-renren-negative;
  }
}
.icon--renren {
  &:before {
    content: $icon-renren;
  }
}
.icon--webcast {
  &:before {
    content: $icon-webcast;
  }
}
.icon--weixin-negative {
  &:before {
    content: $icon-weixin-negative;
  }
}
.icon--weixin {
  &:before {
    content: $icon-weixin;
  }
}
.icon--add-to-download-basket {
  &:before {
    content: $icon-add-to-download-basket;
  }
}
.icon--audio-off {
  &:before {
    content: $icon-audio-off;
  }
}
.icon--download-basket {
  &:before {
    content: $icon-download-basket;
  }
}
.icon--gif {
  &:before {
    content: $icon-gif;
  }
}
.icon--career {
  &:before {
    content: $icon-career;
  }
}
.icon--clock {
  &:before {
    content: $icon-clock;
  }
}
.icon--conference {
  &:before {
    content: $icon-conference;
  }
}
.icon--filter {
  &:before {
    content: $icon-filter;
  }
}
.icon--investor-relations {
  &:before {
    content: $icon-investor-relations;
  }
}
.icon--press {
  &:before {
    content: $icon-press;
  }
}
.icon--question {
  &:before {
    content: $icon-question;
  }
}
.icon--quotation {
  &:before {
    content: $icon-quotation;
  }
}
.icon--rectification {
  &:before {
    content: $icon-rectification;
  }
}
.icon--settings {
  &:before {
    content: $icon-settings;
  }
}
.icon--tickets {
  &:before {
    content: $icon-tickets;
  }
}
.icon--trade-fair {
  &:before {
    content: $icon-trade-fair;
  }
}
.icon--webinar {
  &:before {
    content: $icon-webinar;
  }
}
.icon--aparat-negative {
  &:before {
    content: $icon-aparat-negative;
  }
}
.icon--aparat {
  &:before {
    content: $icon-aparat;
  }
}
.icon--tumblr-negative {
  &:before {
    content: $icon-tumblr-negative;
  }
}
.icon--tumblr {
  &:before {
    content: $icon-tumblr;
  }
}
.icon--fast-backward {
  &:before {
    content: $icon-fast-backward;
  }
}
.icon--fast-forward {
  &:before {
    content: $icon-fast-forward;
  }
}
.icon--pause {
  &:before {
    content: $icon-pause;
  }
}
.icon--replay {
  &:before {
    content: $icon-replay;
  }
}
.icon--skip-backward {
  &:before {
    content: $icon-skip-backward;
  }
}
.icon--skip-forward {
  &:before {
    content: $icon-skip-forward;
  }
}
.icon--stop {
  &:before {
    content: $icon-stop;
  }
}
.icon--audio-down {
  &:before {
    content: $icon-audio-down;
  }
}
.icon--audio-up {
  &:before {
    content: $icon-audio-up;
  }
}
.icon--windows-app-store {
  &:before {
    content: $icon-windows-app-store;
  }
}
.icon--apps {
  &:before {
    content: $icon-apps;
  }
}
.icon--arrow-down-large {
  &:before {
    content: $icon-arrow-down-large;
  }
}
.icon--arrow-left-large {
  &:before {
    content: $icon-arrow-left-large;
  }
}
.icon--arrow-right-large {
  &:before {
    content: $icon-arrow-right-large;
  }
}
.icon--arrow-up-large {
  &:before {
    content: $icon-arrow-up-large;
  }
}
.icon--arrow-down-small {
  &:before {
    content: $icon-arrow-down-small;
  }
}
.icon--arrow-left-small {
  &:before {
    content: $icon-arrow-left-small;
  }
}
.icon--arrow-right-small {
  &:before {
    content: $icon-arrow-right-small;
  }
}
.icon--arrow-up-small {
  &:before {
    content: $icon-arrow-up-small;
  }
}
.icon--arrow-to-top {
  &:before {
    content: $icon-arrow-to-top;
  }
}
.icon--at {
  &:before {
    content: $icon-at;
  }
}
.icon--bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon--calculate {
  &:before {
    content: $icon-calculate;
  }
}
.icon--calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon--chat {
  &:before {
    content: $icon-chat;
  }
}
.icon--check-mark {
  &:before {
    content: $icon-check-mark;
  }
}
.icon--circle {
  &:before {
    content: $icon-circle;
  }
}
.icon--close {
  &:before {
    content: $icon-close;
  }
}
.icon--control {
  &:before {
    content: $icon-control;
  }
}
.icon--delete {
  &:before {
    content: $icon-delete;
  }
}
.icon--double-arrow-backward {
  &:before {
    content: $icon-double-arrow-backward;
  }
}
.icon--double-arrow-down {
  &:before {
    content: $icon-double-arrow-down;
  }
}
.icon--double-arrow-forward {
  &:before {
    content: $icon-double-arrow-forward;
  }
}
.icon--double-arrow-up {
  &:before {
    content: $icon-double-arrow-up;
  }
}
.icon--download {
  &:before {
    content: $icon-download;
  }
}
.icon--drag {
  &:before {
    content: $icon-drag;
  }
}
.icon--edit {
  &:before {
    content: $icon-edit;
  }
}
.icon--email {
  &:before {
    content: $icon-email;
  }
}
.icon--ext {
  &:before {
    content: $icon-ext;
  }
}
.icon--facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon--facebook-negative {
  &:before {
    content: $icon-facebook-negative;
  }
}
.icon--fax {
  &:before {
    content: $icon-fax;
  }
}
.icon--file {
  &:before {
    content: $icon-file;
  }
}
.icon--avi {
  &:before {
    content: $icon-avi;
  }
}
.icon--doc {
  &:before {
    content: $icon-doc;
  }
}
.icon--eps {
  &:before {
    content: $icon-eps;
  }
}
.icon--jpg {
  &:before {
    content: $icon-jpg;
  }
}
.icon--mov {
  &:before {
    content: $icon-mov;
  }
}
.icon--mp3 {
  &:before {
    content: $icon-mp3;
  }
}
.icon--mp4 {
  &:before {
    content: $icon-mp4;
  }
}
.icon--pdf {
  &:before {
    content: $icon-pdf;
  }
}
.icon--png {
  &:before {
    content: $icon-png;
  }
}
.icon--ppt {
  &:before {
    content: $icon-ppt;
  }
}
.icon--files {
  &:before {
    content: $icon-files;
  }
}
.icon--tif {
  &:before {
    content: $icon-tif;
  }
}
.icon--vcf {
  &:before {
    content: $icon-vcf;
  }
}
.icon--xls {
  &:before {
    content: $icon-xls;
  }
}
.icon--zip {
  &:before {
    content: $icon-zip;
  }
}
.icon--forum {
  &:before {
    content: $icon-forum;
  }
}
.icon--fullscreen {
  &:before {
    content: $icon-fullscreen;
  }
}
.icon--globe {
  &:before {
    content: $icon-globe;
  }
}
.icon--glossary {
  &:before {
    content: $icon-glossary;
  }
}
.icon--google {
  &:before {
    content: $icon-google;
  }
}
.icon--google-negative {
  &:before {
    content: $icon-google-negative;
  }
}
.icon--home {
  &:before {
    content: $icon-home;
  }
}
.icon--information {
  &:before {
    content: $icon-information;
  }
}
.icon--information-package {
  &:before {
    content: $icon-information-package;
  }
}
.icon--instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon--instagram-negative {
  &:before {
    content: $icon-instagram-negative;
  }
}
.icon--legal-notice {
  &:before {
    content: $icon-legal-notice;
  }
}
.icon--level-up {
  &:before {
    content: $icon-level-up;
  }
}
.icon--linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon--linkedin-negative {
  &:before {
    content: $icon-linkedin-negative;
  }
}
.icon--list-view {
  &:before {
    content: $icon-list-view;
  }
}
.icon--location-pin {
  &:before {
    content: $icon-location-pin;
  }
}
.icon--log-in {
  &:before {
    content: $icon-log-in;
  }
}
.icon--log-out {
  &:before {
    content: $icon-log-out;
  }
}
.icon--menu {
  &:before {
    content: $icon-menu;
  }
}
.icon--minus {
  &:before {
    content: $icon-minus;
  }
}
.icon--more {
  &:before {
    content: $icon-more;
  }
}
.icon--navigation-gyroscope {
  &:before {
    content: $icon-navigation-gyroscope;
  }
}
.icon--navigation-mouse {
  &:before {
    content: $icon-navigation-mouse;
  }
}
.icon--newton_product-catalogue {
  &:before {
    content: $icon-newton_product-catalogue;
  }
}
.icon--outline {
  &:before {
    content: $icon-outline;
  }
}
.icon--phone {
  &:before {
    content: $icon-phone;
  }
}
.icon--play {
  &:before {
    content: $icon-play;
  }
}
.icon--plus {
  &:before {
    content: $icon-plus;
  }
}
.icon--print {
  &:before {
    content: $icon-print;
  }
}
.icon--rating {
  &:before {
    content: $icon-rating;
  }
}
.icon--region {
  &:before {
    content: $icon-region;
  }
}
.icon--rss {
  &:before {
    content: $icon-rss;
  }
}
.icon--scaling {
  &:before {
    content: $icon-scaling;
  }
}
.icon--search {
  &:before {
    content: $icon-search;
  }
}
.icon--share-ios {
  &:before {
    content: $icon-share-ios;
  }
}
.icon--share-newton-android {
  &:before {
    content: $icon-share-newton-android;
  }
}
.icon--shopping-cart {
  &:before {
    content: $icon-shopping-cart;
  }
}
.icon--show-location {
  &:before {
    content: $icon-show-location;
  }
}
.icon--single-arrow-backward {
  &:before {
    content: $icon-single-arrow-backward;
  }
}
.icon--single-arrow-down {
  &:before {
    content: $icon-single-arrow-down;
  }
}
.icon--single-arrow-forward {
  &:before {
    content: $icon-single-arrow-forward;
  }
}
.icon--single-arrow-up {
  &:before {
    content: $icon-single-arrow-up;
  }
}
.icon--slider {
  &:before {
    content: $icon-slider;
  }
}
.icon--social-feed {
  &:before {
    content: $icon-social-feed;
  }
}
.icon--audio-on {
  &:before {
    content: $icon-audio-on;
  }
}
.icon--subscribe {
  &:before {
    content: $icon-subscribe;
  }
}
.icon--theenergyblog {
  &:before {
    content: $icon-theenergyblog;
  }
}
.icon--theenergyblog-negative {
  &:before {
    content: $icon-theenergyblog-negative;
  }
}
.icon--apple-mac-app-store {
  &:before {
    content: $icon-apple-mac-app-store;
  }
}
.icon--android-app-store {
  &:before {
    content: $icon-android-app-store;
  }
}
.icon--shuffle {
  &:before {
    content: $icon-shuffle;
  }
}
.icon--tile-view {
  &:before {
    content: $icon-tile-view;
  }
}
.icon--twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon--twitter-negative {
  &:before {
    content: $icon-twitter-negative;
  }
}
.icon--user-account {
  &:before {
    content: $icon-user-account;
  }
}
.icon--video-chat {
  &:before {
    content: $icon-video-chat;
  }
}
.icon--warning {
  &:before {
    content: $icon-warning;
  }
}
.icon--youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon--youtube-negative {
  &:before {
    content: $icon-youtube-negative;
  }
}
.icon--zoom {
  &:before {
    content: $icon-zoom;
  }
}
.icon--weibo {
  &:before {
    content: $icon-weibo;
  }
}
.icon--weibo-negative {
  &:before {
    content: $icon-weibo-negative;
  }
}
.icon--youku {
  &:before {
    content: $icon-youku;
  }
}
.icon--youku-negative {
  &:before {
    content: $icon-youku-negative;
  }
}
.icon--support {
  &:before {
    content: $icon-support;
  }
}
.icon--service {
  &:before {
    content: $icon-service;
  }
}

