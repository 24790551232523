.mod-scroll-to-top {

    width: 24px;
    height: 25px;
    right: 30px;
    bottom: -30px;
    opacity: 0;
    // @include icon($icon-gototop); FIXME
    position: fixed;
    cursor: pointer;
    z-index: 100;
    transition: all 0.2s ease-in-out;

    &.show {
        opacity: 1;
        bottom: 30px;
    }

    &:hover {
        transform: scale(1.1);
    }
}
